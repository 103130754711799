<template>
    <div>
        <h3 class="chart_title">
            <img v-if="categoryProgress.icon" :src="categoryProgress.icon" :alt="categoryProgress.title">
            <img v-else-if="categoryProgress.default_icon" :src="require(`@/assets/images/${categoryProgress.default_icon}`)" :alt="categoryProgress.title">

            {{ categoryProgress.title }} <span class="tag" v-if="categoryProgress.unit">{{ categoryProgress.unit }}</span>
            <ul class="tabs" v-if="categoryProgress.is_pain">
                <li @click="painTab = 1" :class="{ active: painTab == 1 }">Upper Body</li>
                <li @click="painTab = 2" :class="{ active: painTab == 2 }">Lower Body</li>
            </ul>
        </h3>
        <div class="widgets_wpr">
            <div class="header" v-if="!categoryProgress.is_vitamins && !categoryProgress.is_minirals && !categoryProgress.is_suppliment">
                <div class="scope_box" @click="circumferenceDropdown = !circumferenceDropdown;" v-click-outside="closeCircumferenceDropdown" v-if="categoryProgress.is_circumference">
                    Choose 3 areas to compare<i class="fas fa-angle-down"></i>
                    <div class="dropdown_wpr" :class="circumferenceDropdown ? 'active' : ''">
                        <ul>
                            <template v-for="(measurement, m) in categoryProgress.settings.measurements" :key="m">
                                <li v-if="measurement.enabled" @click.stop="handleCircumferencesChart(measurement);">
                                    <label class="checkbox">
                                        <span :class="{'active' : circumferencesChartData.findIndex((att) => att.name === Helper.capitalizeFirstLetter(measurement.title).replaceAll('_', ' ')) !== -1 }"><i class="fas fa-check"></i></span>
                                        <h5 class="capitalize">{{ measurement.title.replaceAll('_', ' ') }}</h5>
                                    </label>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
                <template v-else-if="!categoryProgress.is_sleep && !categoryProgress.is_circumference && !categoryProgress.is_vitamins && !categoryProgress.is_minerals && !categoryProgress.is_supplements && !categoryProgress.is_stool && !categoryProgress.is_pain">
                    <label v-if="categoryProgress.has_goal && tracking.has_goal" :for="`${categoryProgress.title}_${categoryProgress.id}_show_goals`.toLowerCase().replaceAll(' ', '_')" class="switch_option capsule_btn p-0 m-0 border-0">
                        <h5 class="mr-3">Show Goals</h5>
                        <input type="checkbox" :id="`${categoryProgress.title}_${categoryProgress.id}_show_goals`.toLowerCase().replaceAll(' ', '_')" v-model="showChartGoals" :true-value="1" :false-value="0" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="item_unit" v-if="categoryProgress.unit">Unit : <span>{{ categoryProgress.unit }}</span></div>
                    <div v-if="1 == 2 && categoryProgress.has_goal && tracking.has_goal && categoryProgress.can_override_goal" class="chart_setup" v-tooltip="`Update Goal`" v-click-outside="closeUpdateGoal">
                        <i class="fas fa-cog" @click="updateGoalModal = !updateGoalModal;"></i>
                        <div class="goal_option" :class="{ 'active' : updateGoalModal }" v-if="categoryProgress.settings && categoryProgress.settings.goals && (categoryProgress.is_circumference || categoryProgress.is_macros || categoryProgress.is_vitamins || categoryProgress.is_minerals || categoryProgress.is_supplements || categoryProgress.is_workout || categoryProgress.is_zones || categoryProgress.is_blood_pressure || categoryProgress.is_sleep || categoryProgress.is_pain || categoryProgress.is_push_up || categoryProgress.is_pull_up)">
                            <div class="goal_item">
                                <h4>
                                    Range
                                    <label :for="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_is_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                        <input type="radio" :disabled="categoryProgress.can_override_goal == 0" :id="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_is_range`" :name="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_goal_range`" v-model="goalForm.type" value="Range" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h4>
                                <div class="subgoal_item" v-for="(goal, g) of categoryProgress.settings.goals" :key="g">
                                    <h4 class="title capitalize">{{ goal.title }}</h4>
                                    <ul v-if="goalForm.goals[g]">
                                        <li class="mb-3">
                                            <label>High Limit</label>
                                            <div class="field_area">
                                                <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.goals[g].high_limit">
                                                <span class="unit" v-if="categoryProgress.unit">{{ categoryProgress.unit }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <label>Low Limit</label>
                                            <div class="field_area">
                                                <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.goals[g].low_limit">
                                                <span class="unit" v-if="categoryProgress.unit">{{ categoryProgress.unit }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="goal_item">
                                <h4>
                                    Specific Target
                                    <label :for="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_not_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                        <input type="radio" :disabled="categoryProgress.can_override_goal == 0" :id="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_not_range`" :name="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_goal_range`" v-model="goalForm.type" value="Target" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h4>
                                <div class="subgoal_item" v-for="(goal, g) of categoryProgress.settings.goals" :key="g">
                                    <h4 class="title capitalize">{{ goal.title }}</h4>
                                    <ul v-if="goalForm.goals[g]">
                                        <li class="mb-3">
                                            <label>Select Target</label>
                                            <div class="target_drops" @click="toggleTargetDropdown">
                                                {{ goalForm.goals[g].target_type }} <i class="fas fa-angle-down"></i>
                                                <div class="dropdown_wpr">
                                                    <ul>
                                                        <li v-for="(target, t) of goalTargetOptions" :key="t" @click.stop="goalForm.goals[g].target_type = target.value; toggleTargetDropdown">{{ target.label }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <label>Value</label>
                                            <div class="field_area">
                                                <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.goals[g].target">
                                                <span class="unit" v-if="categoryProgress.unit">{{categoryProgress.unit}}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button class="btn cancel_btn" @click="updateGoalModal = false;">Cancel</button>
                                <button class="btn save_btn" :disabled="categoryProgress.can_override_goal == 0 || goalUpdateLoader" @click="saveGoal()"><i class="fa fa-spin fa-spinner" v-if="goalUpdateLoader"></i> {{ goalUpdateLoader ? 'Saving' : 'Save' }}</button>
                            </div>
                        </div>
                        <div class="goal_option" :class="{ 'active' : updateGoalModal }" v-else>
                            <div class="goal_item">
                                <h4>
                                    Range
                                    <label :for="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_is_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                        <input type="radio" :disabled="categoryProgress.can_override_goal == 0" :id="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_is_range`" :name="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_goal_range`" v-model="goalForm.type" value="Range" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h4>
                                <ul>
                                    <li class="mb-3">
                                        <label>High Limit</label>
                                        <div class="field_area">
                                            <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.high_limit">
                                            <span class="unit" v-if="categoryProgress.unit">{{ categoryProgress.unit }}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Low Limit</label>
                                        <div class="field_area">
                                            <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.low_limit">
                                            <span class="unit" v-if="categoryProgress.unit">{{ categoryProgress.unit }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="goal_item">
                                <h4>
                                    Specific Target
                                    <label :for="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_not_range`" class="switch_option capsule_btn p-0 m-0 border-0">
                                        <input type="radio" :disabled="categoryProgress.can_override_goal == 0" :id="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_not_range`" :name="`${categoryProgress.name.replaceAll(' ', '_').toLowerCase()}_goal_range`" v-model="goalForm.type" value="Target" hidden>
                                        <div><span></span></div>
                                    </label>
                                </h4>
                                <ul>
                                    <li class="mb-3">
                                        <label>Select Target</label>
                                        <div class="target_drops" @click="goalTargetDropdown = !goalTargetDropdown">
                                            {{ goalForm.target_type }} <i class="fas fa-angle-down"></i>
                                            <div class="dropdown_wpr" :class="{'active' : goalTargetDropdown}">
                                                <ul>
                                                    <li v-for="(target, t) of goalTargetOptions" :key="t" @click.stop="goalForm.target_type = target.value; goalTargetDropdown = false;">{{ target.label }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <label>Value</label>
                                        <div class="field_area">
                                            <input type="number" :disabled="categoryProgress.can_override_goal == 0" min="0" placeholder="0" v-model="goalForm.target">
                                            <span class="unit" v-if="categoryProgress.unit">{{categoryProgress.unit}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="action_wpr">
                                <button class="btn cancel_btn" @click="updateGoalModal = false;">Cancel</button>
                                <button class="btn save_btn" :disabled="categoryProgress.can_override_goal == 0 || goalUpdateLoader" @click="saveGoal()"><i class="fa fa-spin fa-spinner" v-if="goalUpdateLoader"></i> {{ goalUpdateLoader ? 'Saving' : 'Save' }}</button>
                            </div>
                        </div>
                     </div>
                </template>
                <template v-else-if="categoryProgress.is_pain">
                    <label class="w-100 text-center mb-3">{{  painTab == 1 ? 'Upper' : 'Lower'  }} Body</label>
                </template>
            </div>
            <template v-if="categoryProgress.is_pain">
                <vue-echarts v-show="painTab == 1" :option="upperBodyChart" :style="`height: ${canvasHeightUpper}px`" @click="handleChartClick" ref="upperBodyChart"></vue-echarts>
                <vue-echarts v-show="painTab == 2" :option="lowerBodyChart" :style="`height: ${canvasHeightLower}px`" @click="handleChartClick" ref="lowerBodyChart"></vue-echarts>
            </template>
            <vue-echarts v-else :option="chart" :style="`height: ${canvasHeight}px`" @click="handleChartClick" ref="chart"></vue-echarts>
            <span class="chart_title" v-if="categoryProgress.unit">{{ categoryProgress.unit == '%' ? 'Percent' : categoryProgress.unit }}</span>
            <div class="slider_cover"></div>
        </div>
        <div class="modal track_modal" v-if="pullUpPushUpDetailsDrawer" :class="{ 'active' : pullUpPushUpDetailsDrawer }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Details</h1>
                    <button class="close_btn left_out" @click="pullUpPushUpDetailsDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_title">{{ moment(categoryProgress.chart_axis_date[selectedChartSeries.dataIndex]).format('ll') }}</div>
                    <div>
                        <div class="range_limit">
                            <div class="scope_details">
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <div class="scope_item" v-if="measurement.enabled">
                                        <h4 class="capitalize">{{ measurement.title }}:</h4>
                                        <div class="item_data">
                                            <template v-if="measurement.title == 'rest'">
                                                {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest ? Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest.rest_min + ':' + Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest.rest_sec : Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest_min + ':' + Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest_sec }}
                                            </template>
                                            <template v-else>
                                                {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                                <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <template v-if="measurement.enabled">
                                        <h3 class="capitalize">
                                            {{ measurement.title }}
                                            <div class="range_details" v-if="measurement.title != 'rest'" v-html="goalDataLabel(measurement.title)"></div>
                                        </h3>
                                        <ul>
                                            <li>
                                                <h4>
                                                    <label>Value:</label>
                                                    <div>
                                                        <template v-if="measurement.title == 'rest'">
                                                            {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest ? Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest.rest_min + ':' + Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest.rest_sec : Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest_min + ':' + Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].rest_sec }}
                                                        </template>
                                                        <template v-else>
                                                            {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                                            <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                                        </template>
                                                    </div>
                                                </h4>
                                            </li>
                                            <li v-if="measurement.title != 'rest' && categoryProgress.has_goal && tracking.has_goal">
                                                <h4>
                                                    <label>Status:</label>
                                                    <template v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]">
                                                        {{ goalStatusLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                    </template>
                                                    <template v-else>
                                                        Missed
                                                    </template>
                                                </h4>
                                                <div v-if="showChartGoals && Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]" class="flag" :class="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'winner'">
                                                    {{ goalStatusShortLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <div class="scroll_table">
                        <table class="chart_data" v-show="showHistory">
                            <tr>
                                <th>Date</th>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <th v-if="measurement.enabled" class="capitalize">{{ measurement.title }}</th>
                                </template>
                            </tr>
                            <template v-for="(date, d) of categoryProgress.chart_axis_date" :key="d">
                                <tr>
                                    <td>{{ moment(date).format('ll') }}</td>
                                    <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                        <td v-if="measurement.enabled">
                                            <h5>
                                                <template v-if="measurement.title == 'rest' && (categoryProgress.scores[date].rest || categoryProgress.scores[date].rest_min)">
                                                    {{ categoryProgress.scores[date].rest ? categoryProgress.scores[date].rest.rest_min + ':' + categoryProgress.scores[date].rest.rest_sec : categoryProgress.scores[date].rest_min + ':' + categoryProgress.scores[date].rest_sec }}
                                                </template>
                                                <template v-else-if="categoryProgress.scores[date][measurement.title]">
                                                    {{ categoryProgress.scores[date][measurement.title] }}
                                                    <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                                </template>
                                                <template v-else></template>
                                                <label class="state" v-if="categoryProgress.scores[date][measurement.title] && measurement.title != 'rest'" :class="categoryProgress.scores[date][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : categoryProgress.scores[date][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'success'">
                                                    {{ goalStatusShortLabel(measurement.title, d) }}
                                                </label>
                                            </h5>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal track_modal" v-if="macrosDetailsDrawer" :class="{ 'active' : macrosDetailsDrawer }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Details</h1>
                    <button class="close_btn left_out" @click="macrosDetailsDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_title">{{ moment(categoryProgress.chart_axis_date[selectedChartSeries.dataIndex]).format('ll') }}</div>
                    <div>
                        <div class="range_limit">
                            <div class="scope_details">
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <div class="scope_item" v-if="measurement.enabled">
                                        <h4 class="capitalize">{{ measurement.title }}:</h4>
                                        <div class="item_data">
                                            {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                            <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <template v-if="measurement.enabled">
                                        <h3 class="capitalize">
                                            {{ measurement.title }}
                                            <div class="range_details" v-html="goalDataLabel(measurement.title)"></div>
                                        </h3>
                                        <ul>
                                            <li>
                                                <h4>
                                                    <label>Value:</label>
                                                    <div>
                                                        {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                                        <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                                    </div>
                                                </h4>
                                            </li>
                                            <li v-if="categoryProgress.has_goal && tracking.has_goal">
                                                <h4>
                                                    <label>Status:</label>
                                                    <template v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]">
                                                        {{ goalStatusLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                    </template>
                                                    <template v-else>
                                                        Missed
                                                    </template>
                                                </h4>
                                                <div v-if="showChartGoals && Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]" class="flag" :class="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'winner'">
                                                    {{ goalStatusShortLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <div class="scroll_table">
                        <table class="chart_data" v-show="showHistory">
                            <tr>
                                <th>Date</th>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <th v-if="measurement.enabled" class="capitalize">{{ measurement.title }}</th>
                                </template>
                            </tr>
                            <template v-for="(date, d) of categoryProgress.chart_axis_date" :key="d">
                                <tr>
                                    <td>{{ moment(date).format('ll') }}</td>
                                    <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                        <td v-if="measurement.enabled">
                                            <h5>
                                                <template v-if="categoryProgress.scores[date][measurement.title]">
                                                    {{ categoryProgress.scores[date][measurement.title] }}
                                                    <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                                </template>
                                                <template v-else>

                                                </template>
                                                <label class="state" v-if="categoryProgress.scores[date][measurement.title]" :class="categoryProgress.scores[date][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : categoryProgress.scores[date][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'success'">
                                                    {{ goalStatusShortLabel(measurement.title, d) }}
                                                </label>
                                            </h5>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal track_modal" v-if="circumferencesDetailsDrawer" :class="{ 'active' : circumferencesDetailsDrawer }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Details</h1>
                    <button class="close_btn left_out" @click="circumferencesDetailsDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_title">{{ moment(categoryProgress.chart_axis_date[selectedChartSeries.dataIndex]).format('ll') }}</div>
                    <div>
                        <div class="range_limit">
                            <div class="scope_details">
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <div class="scope_item" v-if="measurement.enabled">
                                        <h4 class="capitalize">{{ measurement.title }}:</h4>
                                        <div class="item_data">
                                            {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                            <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <template v-if="measurement.enabled">
                                        <h3 class="capitalize">
                                            {{ measurement.title }}
                                            <div class="range_details" v-html="goalDataLabel(measurement.title)"></div>
                                        </h3>
                                        <ul>
                                            <li>
                                                <h4>
                                                    <label>Value:</label>
                                                    <div>
                                                        {{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] }}
                                                        <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                                    </div>
                                                </h4>
                                            </li>
                                            <li v-if="categoryProgress.has_goal && tracking.has_goal">
                                                <h4>
                                                    <label>Status:</label>
                                                    <template v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]">
                                                        {{ goalStatusLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                    </template>
                                                    <template v-else>
                                                        Missed
                                                    </template>
                                                </h4>
                                                <div v-if="showChartGoals && Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title]" class="flag" :class="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'winner'">
                                                    {{ goalStatusShortLabel(measurement.title, selectedChartSeries.dataIndex) }}
                                                </div>
                                            </li>
                                        </ul>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <div class="scroll_table">
                        <table class="chart_data" v-show="showHistory">
                            <tr>
                                <th>Date</th>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <th v-if="measurement.enabled" class="capitalize">{{ measurement.title }}</th>
                                </template>
                            </tr>
                            <tr v-for="(date, d) of categoryProgress.chart_axis_date" :key="d">
                                <td>{{ moment(date).format('ll') }}</td>
                                <template v-for="(measurement, m) of categoryProgress.settings.measurements" :key="m">
                                    <td v-if="measurement.enabled">
                                        <h5>
                                            <template v-if="categoryProgress.scores[date][measurement.title]">
                                                {{ categoryProgress.scores[date][measurement.title] }}
                                                <span v-if="categoryProgress.unit_label">{{ categoryProgress.unit_label }}</span>
                                            </template>
                                            <template v-else>

                                            </template>
                                            <label class="state" v-if="categoryProgress.scores[date][measurement.title]" :class="categoryProgress.scores[date][measurement.title] > categoryProgress.settings.goals[m].high_limit ? 'high' : categoryProgress.scores[date][measurement.title] < categoryProgress.settings.goals[m].low_limit ? 'low' : 'success'">
                                                {{ goalStatusShortLabel(measurement.title, d) }}
                                            </label>
                                        </h5>
                                    </td>
                                </template>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal track_modal" v-if="painDetailsDrawer" :class="{ 'active' : painDetailsDrawer }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Details</h1>
                    <button class="close_btn left_out" @click="painDetailsDrawer = false;"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_title">{{ moment(categoryProgress.chart_axis_date[selectedChartSeries.dataIndex]).format('ll') }}</div>
                    <div>
                        <div class="pain_limit" v-if="painTab == 1">
                            <template v-for="(upper, u) of categoryProgress.settings.upper_body" :key="u">
                                <div class="pain_item" v-if="upper.enabled">
                                    <div class="positions">
                                        <label class="capitalize">{{ upper.title }}</label>
                                        <ul v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex]">
                                            <li v-for="(pos, p) of Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].positions[upper.title]" :key="p">{{ pos }}</li>
                                        </ul>
                                    </div>
                                    <h4>
                                        <div v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex]">{{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].pain_level[upper.title] }}/10</div>
                                        <div v-else>0/10</div>
                                        <label>Pain</label>
                                    </h4>
                                </div>
                            </template>
                        </div>
                        <div class="pain_limit" v-else-if="painTab == 2">
                            <template v-for="(lower, l) of categoryProgress.settings.lower_body" :key="l">
                                <div class="pain_item" v-if="lower.enabled">
                                    <div class="positions">
                                        <label class="capitalize">{{ lower.title }}</label>
                                        <ul v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex]">
                                            <li v-for="(pos, p) of Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].positions[lower.title]" :key="p">{{ pos }}</li>
                                        </ul>
                                    </div>
                                    <h4>
                                        <div v-if="Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex]">{{ Object.values(categoryProgress.scores)[selectedChartSeries.dataIndex].pain_level[lower.title] }}/10</div>
                                        <div v-else>0/10</div>
                                        <label>Pain</label>
                                    </h4>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="section_title">History <span class="show_hide" @click="showHistory = !showHistory">{{ showHistory ? 'Hide' : 'Show' }}</span></div>
                    <div class="scroll_table">
                        <table class="chart_data" v-show="showHistory">
                            <template v-if="painTab == 1">
                                <tr>
                                    <th>Date</th>
                                    <template v-for="(upper, u) of categoryProgress.settings.upper_body" :key="u">
                                        <th v-if="upper.enabled" class="capitalize">{{ upper.title }}</th>
                                    </template>
                                </tr>
                                <tr v-for="(date, d) of categoryProgress.chart_axis_date" :key="d">
                                    <td>{{ moment(date).format('ll') }}</td>
                                    <template v-for="(upper, u) of categoryProgress.settings.upper_body" :key="u">
                                        <td v-if="upper.enabled">
                                            <h5 v-if="categoryProgress.scores[date].pain_level && categoryProgress.scores[date].pain_level[upper.title]">{{ categoryProgress.scores[date].pain_level[upper.title] }}/10</h5>
                                            <h5 v-else>0/10</h5>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                            <template v-else-if="painTab == 2">
                                <tr>
                                    <th>Date</th>
                                    <template v-for="(lower, u) of categoryProgress.settings.lower_body" :key="u">
                                        <th v-if="lower.enabled" class="capitalize">{{ lower.title }}</th>
                                    </template>
                                </tr>
                                <tr v-for="(date, d) of categoryProgress.chart_axis_date" :key="d">
                                    <td>{{ moment(date).format('ll') }}</td>
                                    <template v-for="(lower, l) of categoryProgress.settings.lower_body" :key="l">
                                        <td v-if="lower.enabled">
                                            <h5 v-if="categoryProgress.scores[date].pain_level && categoryProgress.scores[date].pain_level[lower.title]">{{ categoryProgress.scores[date].pain_level[lower.title] }}/10</h5>
                                            <h5 v-else>0/10</h5>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { VueEcharts } from 'vue3-echarts'
    import moment from 'moment'

    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'progress-response-chart',

        data () {
            return {
                moment,
                showChartGoals: 1,
                updateGoalModal: false,
                goalUpdateLoader: false,
                circumferenceDropdown: false,
                canvasHeight: 350,
                canvasHeightUpper: 350,
                canvasHeightLower: 350,
                Helper,
                markAreaColor: 'rgba(47,127,237,0.08)',
                chartColor: '#2f7eed',
                heatmapChartColors: ['#e9e9e9', '#2f7eed50', '#2f7eed50', '#2f7eed50', '#2f7eed50', '#2f7eed50'],
                chart : {
                    legend: {
                        data: [],
                        right: 0,
                        top: '5%',
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: null,
                        className: 'charts_tooltip',
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                        axisPointer: {
                            type: "none"
                        }
                    },
                    grid: {
                        left: 25,
                        right: 25,
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: [],
                        offset: 10,
                        axisTick: {
                            length: 4,
                            lineStyle:{
                                type: "solid",
                                color: "#2F7FED",
                                cap: "round",
                                width: 0
                            }
                        },
                        axisLine:{
                            show: false,
                            lineStyle:{
                                color: "#E8E7E7",
                            }
                        },
                        axisLabel: {
                            color: '#4D4D4D',
                        },
                    },
                    yAxis: {
                        type: 'value',
                        offset: 15,
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            color: '#4D4D4D',
                        },
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                },
                series: {
                    name: '',
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 10,
                    lineStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: '#2F7FED'
                                },
                                {
                                    offset: 1,
                                    color: '#88CBEE'
                                }
                            ],
                            global: false
                        }
                    },
                    data: [],
                    markPoint: {
                        symbol: 'circle',
                        symbolSize: 20,
                        label: {
                            fontSize: 10,
                            color: '#fff'
                        },
                        data: [],
                    },
                    markLine: {
                        data: [
                            { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                            { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                        ],
                        label: {
                            formatter (params) {
                                if (params.name == 'Less') {
                                    return `{down_image|} \n {value|${params.value}}`
                                } else if (params.name == 'Greater') {
                                    return `{up_image|} \n {value|${params.value}}`
                                }
                            },
                            color: '#2f7eed',
                            fontFamily: "'Inter', sans-serif;",
                            rich: {
                                value: {
                                    align: 'center',
                                },
                                up_image: {
                                    height: 20,
                                    align: 'right',
                                    backgroundColor: {
                                        image: '',
                                    }
                                },
                                down_image: {
                                    height: 20,
                                    align: 'right',
                                    backgroundColor: {
                                        image: '',
                                    }
                                }
                            },
                        },
                        lineStyle:{
                            opacity: 1,
                            width: 0,
                        },
                        symbol: 'none',
                    },
                    markArea: {
                        data: [
                            [
                                { yAxis: 0 },
                                { yAxis: 0 }
                            ],
                        ],
                        itemStyle: {
                            color: 'rgba(47,127,237,0.08)',
                            opacity: 1,
                        },
                    }
                },
                circumferencesChartData: [],
                painTab: 1,
                upperBodyChart: {},
                lowerBodyChart: {},
                goalForm: { type: 'Range', goals: [] },
                goalTargetDropdown: false,
                goalTargetOptions: [
                    { value: 'Greater', label: 'Greater Than' },
                    { value: 'Less', label: 'Less Than' },
                    { value: 'Equal', label: 'Equal To' },
                ],
                painDetailsDrawer: false,
                pullUpPushUpDetailsDrawer: false,
                circumferencesDetailsDrawer: false,
                macrosDetailsDrawer: false,
                selectedChartSeries: {},
                showHistory: false,
                arrowUpImage: '',
                arrowDownImage: '',
            };
        },

        props: {
            categoryProgress: Object,
            tracking: Object,
            settings: Object,
            trackingGoal: Object,
        },

        components: {
            VueEcharts,
        },

        watch: {
            categoryProgress: {
                handler (progress) {
                    const vm = this;

                    if (vm.categoryProgress.is_pain) {
                        vm.resetPainChart();
                    } else {
                        vm.resetChart();
                    }
                },
                deep: true
            },

            showChartGoals (val) {
                const vm = this;

                if (vm.chart.series.length == 1) {
                    if (vm.chart.series[0].markLine) {
                        vm.chart.series[0].markLine.lineStyle.opacity = val;
                        vm.chart.series[0].markArea.itemStyle.opacity = val;
                    }

                    if (!val && vm.chart.series[0].markPoint) {
                        vm.chart.series[0].markPoint.data = [];
                    } else {
                        vm.markChartPoints();
                    }
                } else {
                    for (let g = 0; g < vm.chart.series.length; g++) {
                        if (vm.chart.series[g].markLine) {
                            vm.chart.series[g].markLine.lineStyle.opacity = val;
                            vm.chart.series[g].markArea.itemStyle.opacity = val;
                        }

                        if (!val && vm.chart.series[g].markPoint) {
                            vm.chart.series[g].markPoint.data = [];
                        }
                    }

                    if (val) {
                        vm.markChartPoints();
                    }
                }

                if (vm.$refs.chart) {
                    vm.$refs.chart.refreshChart();
                }
            },
        },

        mounted () {
            const vm = this;

            vm.arrowUpImage     = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${vm.hexToRgb(vm.chartColor)}" viewBox="0 0 16 16"><path d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/></svg>`;
            vm.arrowDownImage   = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${vm.hexToRgb(vm.chartColor)}" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"/></svg>`;

            vm.showChartGoals = vm.categoryProgress.has_goal && vm.tracking.has_goal;

            if (vm.categoryProgress.settings && vm.categoryProgress.settings.goals && (vm.categoryProgress.is_circumference || vm.categoryProgress.is_macros || vm.categoryProgress.is_vitamins || vm.categoryProgress.is_minerals || vm.categoryProgress.is_supplements || vm.categoryProgress.is_workout || vm.categoryProgress.is_zones || vm.categoryProgress.is_blood_pressure || vm.categoryProgress.is_sleep || vm.categoryProgress.is_pain || vm.categoryProgress.is_push_up || vm.categoryProgress.is_pull_up)) {
                const goals = vm.trackingGoal.goals ? JSON.parse(JSON.stringify(vm.trackingGoal.goals)) : JSON.parse(JSON.stringify(vm.trackingGoal));

                goals.forEach((goal, g) => {
                    goals[g].target_type = goals[g].target_type ? goals[g].target_type : 'Greater';
                    goals[g].target = goals[g].target ? goals[g].target : 0;
                    goals[g].type = goals[g].target ? goals[g].type : 'Range';
                });

                vm.goalForm.goals   = goals;
                vm.goalForm.type    = vm.trackingGoal.type;
            } else {
                vm.goalForm = {
                    type: vm.trackingGoal.type,
                    high_limit: vm.trackingGoal.high_limit,
                    low_limit: vm.trackingGoal.low_limit,
                    target_type: vm.trackingGoal.target_type ? vm.trackingGoal.target_type : 'Greater',
                    target: vm.trackingGoal.target ? vm.trackingGoal.target : 0,
                    goals: [],
                };
            }

            if (vm.categoryProgress.is_circumference) {
                vm.handleCircumferencesChart();
            }

            if (vm.categoryProgress.is_pain) {
                vm.resetPainChart();
            } else {
                vm.resetChart();
            }
        },

        methods: {
            closeUpdateGoal () {
                const vm = this;

                vm.updateGoalModal = false;
            },

            closeCircumferenceDropdown () {
                const vm = this;

                vm.circumferenceDropdown = false;
            },

            resetChart () {
                const vm = this;
                const scoreLenght = Object.values(vm.categoryProgress.scores).length

                if (vm.settings.chart_settings && vm.settings.chart_settings.graph_accent_color) {
                    const color = vm.settings.chart_settings.graph_accent_color;

                    vm.markAreaColor        = color+'20';
                    vm.chartColor           = color;
                    vm.heatmapChartColors   = ['#e9e9e9'];
                    vm.chart.color          = color;

                    vm.series.lineStyle.color.colorStops[0].color   = color;
                    vm.series.lineStyle.color.colorStops[1].color   = color+'40';
                    vm.chart.xAxis.axisTick.lineStyle.color         = color;

                    vm.chart.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 2);

                    for (let i = 0; i < 8; i++) {
                        vm.heatmapChartColors.push(color+'50');
                    }

                    vm.arrowUpImage     = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${vm.hexToRgb(vm.chartColor)}" viewBox="0 0 16 16"><path d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"/></svg>`;
                    vm.arrowDownImage   = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${vm.hexToRgb(vm.chartColor)}" viewBox="0 0 16 16"><path d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"/></svg>`;
                }

                vm.chart.series     = [];
                vm.chart.xAxis.data = [];

                Object.values(vm.categoryProgress.chart_x_axis).forEach((xAxis) => {
                    vm.chart.xAxis.data.push(xAxis);
                })

                if (vm.categoryProgress.is_vitamins || vm.categoryProgress.is_minerals || vm.categoryProgress.is_supplements) {
                    vm.chart        = vm.parseVitaminsMiniralSupplimentChart();
                    vm.canvasHeight = (vm.chart.series.length * 50) + 80;
                    vm.chart.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 1);
                } else if (vm.categoryProgress.is_workout) {
                    vm.chart        = vm.parseWorkoutChart();
                    vm.canvasHeight = (vm.chart.series.length * 50) + 80;
                    vm.chart.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 1);
                } else if (vm.categoryProgress.is_stool) {
                    vm.chart        = vm.parseStoolChart();
                    vm.canvasHeight = (vm.chart.series.length * 60) + 80;
                    vm.chart.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 1);
                } else if (vm.categoryProgress.is_sleep) {
                    vm.chart        = vm.parseSleepSeries();
                    vm.canvasHeight = (vm.chart.series.length * 60) + 80;
                    vm.chart.media[0].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[1].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 7);
                    vm.chart.media[2].option.dataZoom[0].start = (100 - (100 / scoreLenght) * 1);
                } else {
                    const series = JSON.parse(JSON.stringify(vm.series));
                    const scoreLenght = Object.values(vm.categoryProgress.scores).length

                    series.name = vm.categoryProgress.title;

                    if (vm.categoryProgress.is_circumference) {
                        vm.chart.series     = vm.circumferencesChartData;
                        vm.chart.tooltip    = [];
                    } else if (vm.categoryProgress.is_pull_up || vm.categoryProgress.is_push_up) {
                        vm.chart.series     = vm.parsePullUpPushUpSeries();
                        vm.chart.tooltip    = [];
                    } else if (vm.categoryProgress.is_macros) {
                        vm.chart.series     = vm.parseMacrosSeries();
                        vm.chart.tooltip    = [];
                    } else if (vm.categoryProgress.is_zones) {
                        vm.chart.series     = vm.parseHeartRateZonesSeries();
                        vm.chart.tooltip    = [];
                    } else if (vm.categoryProgress.is_blood_pressure) {
                        vm.chart.series     = vm.parseBloodPressureSeries();
                        vm.chart.tooltip    = [];
                    } else {
                        Object.values(vm.categoryProgress.scores).forEach((score) => {
                            series.data.push(score);
                        });

                        if (vm.categoryProgress.has_goal && vm.tracking.has_goal) {
                            if (vm.goalForm.type == 'Range') {
                                series.markLine = {
                                    data: [
                                        { name: 'Max', yAxis: vm.goalForm.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: vm.goalForm.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        position: 'end',
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                              align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series.markArea = {
                                    data: [
                                        [
                                            { yAxis: vm.goalForm.high_limit },
                                            { yAxis: vm.goalForm.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                };
                            } else {
                                series.markLine = {
                                    data: [
                                        { name: vm.goalForm.target_type, yAxis: vm.goalForm.target, label: { position: vm.goalForm.target_type == 'Less' ? 'insideEndBottom' : (vm.goalForm.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: vm.goalForm.target_type, yAxis: vm.goalForm.target, label: { position: vm.goalForm.target_type == 'Greater' ? 'insideEndTop' : (vm.goalForm.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                              align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series.markArea = {
                                    data: [
                                        [
                                            { yAxis: vm.goalForm.target },
                                            { yAxis: vm.goalForm.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        }

                        vm.chart.series.push(series);
                    }

                    if (vm.chart.series.length == 1) {
                        if (vm.chart.tooltip.axisPointer) {
                            vm.chart.tooltip.axisPointer.type = "none";
                        }

                        vm.chart.legend.data = [];

                        if (vm.categoryProgress.has_goal && vm.tracking.has_goal) {
                            vm.chart.tooltip.formatter = function (params) {
                                if (params[0].value === '' && params[0].value !== 0) {
                                    return params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': &nbsp; </div>';
                                } else if (params[0].value === 0) {
                                    return params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': 0 </div>';
                                }

                                if (vm.chart.series[0].markLine.data[0].name == 'Greater') {
                                    return (params[0].value > 0 && params[0].value < vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '<i class="fas fa-flag-checkered" style="color: #30C13E;"></i> In Range <br />') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': ' + params[0].value + (vm.categoryProgress.unit_label ? vm.categoryProgress.unit_label == 'ratings' ? `/${vm.categoryProgress.max_limit}` : ` ${vm.categoryProgress.unit_label}` : '' ) + '</div>';
                                } else if (vm.chart.series[0].markLine.data[0].name == 'Less') {
                                    return (params[0].value > 0 && params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : '<i class="fas fa-flag-checkered" style="color: #30C13E;"></i> In Range <br />') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': ' + params[0].value + (vm.categoryProgress.unit_label ? vm.categoryProgress.unit_label == 'ratings' ? `/${vm.categoryProgress.max_limit}` : ` ${vm.categoryProgress.unit_label}` : '' ) + '</div>';
                                } else if (vm.chart.series[0].markLine.data[0].name == 'Equal') {
                                    return (params[0].value > 0 && params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : params[0].value > 0 && params[0].value < vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '<i class="fas fa-flag-checkered" style="color: #30C13E;"></i> In Range <br />') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': ' + params[0].value + (vm.categoryProgress.unit_label ? vm.categoryProgress.unit_label == 'ratings' ? `/${vm.categoryProgress.max_limit}` : ` ${vm.categoryProgress.unit_label}` : '' ) + '</div>';
                                } else {
                                    if (vm.chart.series[0].markLine.data[1] && vm.chart.series[0].markLine.data[1].yAxis) {
                                        return (params[0].value > 0 && params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : params[0].value > 0 && params[0].value < vm.chart.series[0].markLine.data[1].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '<i class="fas fa-flag-checkered" style="color: #30C13E;"></i> In Range <br />') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': ' + params[0].value + (vm.categoryProgress.unit_label ? vm.categoryProgress.unit_label == 'ratings' ? `/${vm.categoryProgress.max_limit}` : ` ${vm.categoryProgress.unit_label}` : '' ) + '</div>';
                                    } else {
                                        return (params[0].value > 0 && params[0].value > vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #ffa0a0;"></i> High <br />' : params[0].value > 0 && params[0].value < vm.chart.series[0].markLine.data[0].yAxis ? '<i class="fas fa-flag-checkered" style="color: #a0d3ff;"></i> Low <br />' : '<i class="fas fa-flag-checkered" style="color: #30C13E;"></i> In Range <br />') + params[0].name + '<br /><div style="display:flex; align-items:center;">' + vm.categoryProgress.title + ': ' + params[0].value + (vm.categoryProgress.unit_label ? vm.categoryProgress.unit_label == 'ratings' ? `/${vm.categoryProgress.max_limit}` : ` ${vm.categoryProgress.unit_label}` : '' ) + '</div>';
                                    }
                                }
                            }
                        }
                    } else {
                        for (let i = 0; i < vm.chart.series.length; i++) {
                            vm.chart.legend.data.push(vm.chart.series[i].name);
                        }

                        vm.chart.tooltip.formatter = function (params) {
                            vm.chart.tooltip.axisPointer.type = "shadow";

                            const tooltips = [];
                            tooltips.push(params[0].axisValue);

                            params.forEach(series => {
                                tooltips.push(`${series.marker} ${series.seriesName}: ${series.value}`);
                            });

                            return tooltips.join('<br>');
                        }
                    }
                }

                if (vm.categoryProgress.has_goal && vm.tracking.has_goal) {
                    vm.markChartPoints();
                } else {
                    vm.chart.series.forEach((s, i) => {
                        delete vm.chart.series[i].markPoint;
                        delete vm.chart.series[i].markLine;
                        delete vm.chart.series[i].markArea;
                    });
                }

                if (
                    !vm.categoryProgress.is_vitamins && !vm.categoryProgress.is_minerals &&
                    !vm.categoryProgress.is_supplements && !vm.categoryProgress.is_workout &&
                    !vm.categoryProgress.is_stool && !vm.categoryProgress.is_sleep
                ) {
                    vm.chart.yAxis.max = vm.chartYAxisMaxValue(vm.chart);
                }

                if (vm.$refs.chart) {
                    vm.$refs.chart.refreshChart();
                }
            },

            chartYAxisMaxValue (chart) {
                const vm    = this;
                const data  = [];
                let maxGoal = chart.series[0].markArea ? parseInt(chart.series[0].markArea.data[0][0].yAxis) : 0;

                Object.values(chart.series[0].data).forEach((score) => {
                    if (isNaN(parseInt(score))) {
                        data.push(0);
                    } else {
                        data.push(parseInt(score));
                    }
                });

                if (vm.categoryProgress.is_macros || vm.categoryProgress.is_pull_up || vm.categoryProgress.is_push_up) {
                    const scores = [];

                    Object.values(chart.series).forEach((series) => {
                        Object.values(series.data).forEach((score, s) => {
                            // data.push(parseInt(score));
                            let ss = score;

                            if (isNaN(parseInt(score))) {
                                ss = 0;
                            }

                            scores[s] = scores[s] ? scores[s] + parseInt(score) : parseInt(score);
                        });
                    })

                    Object.values(scores).forEach((score) => {
                        if (isNaN(parseInt(score))) {
                            data.push(0);
                        } else {
                            data.push(parseInt(score));
                        }
                    })
                } else {
                    if (chart.series.length > 1) {
                        Object.values(chart.series).forEach((series) => {
                            Object.values(series.data).forEach((score) => {
                                if (isNaN(parseInt(score))) {
                                    data.push(0);
                                } else {
                                    data.push(parseInt(score));
                                }
                            });
                        })
                    }
                }

                const max   = Math.max(...data);
                const score = max > Math.ceil(maxGoal * 1.40) ? Math.ceil(max * 1.10) : Math.ceil(maxGoal * 1.40);

                return score;
            },

            resetPainChart () {
                const vm = this;
                const scoreLenght = Object.values(vm.categoryProgress.scores).length

                vm.upperBodyChart   = vm.parsePainChart('upper');
                vm.lowerBodyChart   = vm.parsePainChart('lower');

                vm.canvasHeightUpper    = (vm.upperBodyChart.series.length * 50) + 80;
                vm.canvasHeightLower    = (vm.lowerBodyChart.series.length * 50) + 80;

                // vm.upperBodyChart.yAxis.max = vm.chartYAxisMaxValue(vm.upperBodyChart);
                // vm.lowerBodyChart.yAxis.max = vm.chartYAxisMaxValue(vm.lowerBodyChart);

                if (vm.$refs.upperBodyChart) {
                    vm.$refs.upperBodyChart.refreshChart();
                }

                if (vm.$refs.lowerBodyChart) {
                    vm.$refs.lowerBodyChart.refreshChart();
                }
            },

            markChartPoints () {
                const vm = this;

                let mainData = vm.chart.series;

                if (vm.chart.series.length == 1) {
                    if (vm.chart.series[0].markPoint) {
                        vm.chart.series[0].markPoint.data = [];

                        let data = mainData[0].data;

                        for (let i = 0; i < data.length; i++) {
                            const subArray = data[i];
                            const secondItem = subArray;

                            if (vm.chart.series[0].markLine.data[0].name == 'Greater') {
                                if (secondItem > 0 && secondItem < vm.chart.series[0].markLine.data[1].yAxis) {
                                    if (parseInt(secondItem)) {
                                        vm.chart.series[0].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }
                                }
                            } else if (vm.chart.series[0].markLine.data[0].name == 'Less') {
                                if (secondItem > 0 && secondItem > vm.chart.series[0].markLine.data[0].yAxis) {
                                    vm.chart.series[0].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                }
                            } else if (vm.chart.series[0].markLine.data[0].name == 'Equal') {
                                if (secondItem > 0 && secondItem > vm.chart.series[0].markLine.data[0].yAxis) {
                                    vm.chart.series[0].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                }

                                if (secondItem > 0 && secondItem < vm.chart.series[0].markLine.data[1].yAxis) {
                                    if (parseInt(secondItem)) {
                                        vm.chart.series[0].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }
                                }
                            } else {
                                if (secondItem > 0 && secondItem > vm.chart.series[0].markLine.data[0].yAxis) {
                                    vm.chart.series[0].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                }

                                if (secondItem > 0 && secondItem < vm.chart.series[0].markLine.data[1].yAxis) {
                                    if (parseInt(secondItem)) {
                                        vm.chart.series[0].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (let s = 0; s < vm.chart.series.length; s++) {
                        if (vm.chart.series[s].markPoint) {
                            vm.chart.series[s].markPoint.data = [];
                            let data = mainData[s].data;

                            for (let i = 0; i < data.length; i++) {
                                const subArray = data[i];
                                const secondItem = subArray;

                                if (vm.chart.series[s].markLine.data[0].name == 'Greater') {
                                    if (secondItem > 0 && secondItem < vm.chart.series[s].markLine.data[1].yAxis) {
                                        if (parseInt(secondItem)) {
                                            vm.chart.series[s].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                        }
                                    }
                                } else if (vm.chart.series[s].markLine.data[0].name == 'Less') {
                                    if (secondItem > 0 && secondItem > vm.chart.series[s].markLine.data[0].yAxis) {
                                        vm.chart.series[s].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }
                                } else if (vm.chart.series[s].markLine.data[0].name == 'Equal') {
                                    if (secondItem > 0 && secondItem > vm.chart.series[s].markLine.data[0].yAxis) {
                                        vm.chart.series[s].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }

                                    if (secondItem > 0 && secondItem < vm.chart.series[s].markLine.data[1].yAxis) {
                                        if (parseInt(secondItem)) {
                                            vm.chart.series[s].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                        }
                                    }
                                } else {
                                    if (secondItem > 0 && secondItem > vm.chart.series[s].markLine.data[0].yAxis) {
                                        vm.chart.series[s].markPoint.data.push({ value: 'H', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                    }

                                    if (secondItem > 0 && secondItem < vm.chart.series[s].markLine.data[1].yAxis) {
                                        if (parseInt(secondItem)) {
                                            vm.chart.series[s].markPoint.data.push({ value: 'L', xAxis: i, yAxis: secondItem, itemStyle: {color: '#666'}});
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },

            parsePullUpPushUpSeries () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;

                const series = [
                    {
                        name: 'Sets',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `S ${params.value}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 1,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    },
                    {
                        name: 'Reps',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `R ${params.value}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 0.75,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    },
                    {
                        name: 'Rest',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `R ${vm.parseMinutesSeconds(params.value * 60)}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 0.50,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    }
                ];

                if (vm.categoryProgress.has_goal && vm.tracking.has_goal && vm.categoryProgress.settings && vm.trackingGoal) {
                    vm.goalForm.goals.forEach((goal) => {
                        if (goal.title == 'sets') {
                            if (goal.type == 'Range') {
                                series[0].markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series[0].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            } else {
                                series[0].markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series[0].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        } else if (goal.title == 'reps') {
                            if (goal.type == 'Range') {
                                series[1].markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle: {
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series[1].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            } else {
                                series[1].markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series[1].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        }
                    })
                }

                settings.forEach((setting) => {
                    const index = series.findIndex(obj => obj.name.toLowerCase() == setting.title.toLowerCase());

                    if (setting.enabled && setting.title == 'sets') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                series[0].data.push(score.sets);
                            } else {
                                series[0].data.push(0);
                            }
                        })
                    } else if (setting.enabled && setting.title == 'reps') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                series[1].data.push(score.reps);
                            } else {
                                series[1].data.push(0);
                            }
                        })
                    } else if (setting.enabled && setting.title == 'rest') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                let seconds = 0;

                                if (score.rest) {
                                    seconds = (((parseInt(score.rest.rest_min) * 60) + parseInt(score.rest.rest_sec)) / 60);
                                } else if (score.rest_min) {
                                    seconds = (((parseInt(score.rest_min) * 60) + parseInt(score.rest_sec)) / 60);
                                }

                                series[2].data.push(Helper.twoDigitNumber(seconds));
                            } else {
                                series[2].data.push(0);
                            }
                        })
                    }
                })

                settings.forEach((setting) => {
                    const index = series.findIndex(obj => obj.name.toLowerCase() == setting.title.toLowerCase());

                    if (setting.enabled == 0 && setting.title == 'sets') {
                        series.splice(index, 1);
                    } else if (setting.enabled == 0 && setting.title == 'reps') {
                        series.splice(index, 1);
                    } else if (setting.enabled == 0 && setting.title == 'rest') {
                        series.splice(index, 1);
                    }
                });

                return series;
            },

            parseMacrosSeries () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;

                const series = [
                    {
                        name: 'Fat',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `F ${params.value}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 1,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle: {
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    },
                    {
                        name: 'Protein',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `P ${params.value}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 0.75,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    },
                    {
                        name: 'Carbs',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                            color: '#fff',
                            opacity: 1,
                            backgroundColor: 'transparent',
                            formatter: function(params) {
                                return `C ${params.value}`;
                            }
                        },
                        data: [],
                        barWidth: 70,
                        itemStyle: {
                            color: vm.chartColor,
                            opacity: 0.50,
                        },
                        markPoint: {
                            symbol: 'circle',
                            symbolSize: 0,
                            label: {
                                show: false,
                                fontSize: 10,
                                color: '#fff',
                            },
                            data: [],
                        },
                        markLine: {
                            data: [
                                { name: 'Max', yAxis: 0, label: { position: 'insideEndTop'} },
                                { name: 'Min', yAxis: 0, label: { position: 'insideEndBottom'} },
                            ],
                            label: {
                                formatter (params) {
                                    if (params.name == 'Less') {
                                        return `{down_image|} \n {value|${params.value}}`
                                    } else if (params.name == 'Greater') {
                                        return `{up_image|} \n {value|${params.value}}`
                                    }
                                },
                                color: vm.chartColor,
                                fontFamily: "'Inter', sans-serif;",
                                rich: {
                                    value: {
                                        align: 'center',
                                    },
                                    up_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowUpImage,
                                        }
                                    },
                                    down_image: {
                                        height: 20,
                                        align: 'right',
                                        backgroundColor: {
                                            image: vm.arrowDownImage,
                                        }
                                    }
                                },
                            },
                            lineStyle:{
                                opacity: 1,
                                width: 0,
                            },
                            symbol: 'none',
                        },
                        markArea: {
                            data: [
                                [
                                    { yAxis: 0 },
                                    { yAxis: 0 }
                                ],
                            ],
                            itemStyle: {
                                color: vm.markAreaColor,
                                opacity: 0,
                            },
                        },
                        media: [
                            {
                                query: {
                                    maxWidth: 499
                                },
                                option: {
                                    barWidth: 50,
                                }
                            },
                        ]
                    }
                ];

                if (vm.categoryProgress.has_goal && vm.tracking.has_goal && vm.categoryProgress.settings && vm.trackingGoal) {
                    vm.goalForm.goals.forEach((goal) => {
                        if (goal.title == 'fat') {
                            if (goal.type == 'Range') {
                                series[0].markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series[0].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            } else {
                                series[0].markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series[0].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        } else if (goal.title == 'protein') {
                            if (goal.type == 'Range') {
                                series[1].markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series[1].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                };
                            } else {
                                series[1].markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series[1].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        } else if (goal.title == 'carbs') {
                            if (goal.type == 'Range') {
                                series[2].markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                series[2].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                };
                            } else {
                                series[2].markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle: {
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                series[2].markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        }
                    })
                }

                settings.forEach((setting) => {
                    const index = series.findIndex(obj => obj.name.toLowerCase() == setting.title.toLowerCase());

                    if (setting.enabled && setting.title == 'fat') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                series[0].data.push(score.fat);
                            } else {
                                series[0].data.push(0);
                            }
                        })
                    } else if (setting.enabled && setting.title == 'protein') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                series[1].data.push(score.protein);
                            } else {
                                series[1].data.push(0);
                            }
                        })
                    } else if (setting.enabled && setting.title == 'carbs') {
                        Object.values(scores).forEach((score) => {
                            if (isNaN(score)) {
                                series[2].data.push(score.carbs);
                            } else {
                                series[2].data.push(0);
                            }
                        })
                    }
                });

                settings.forEach((setting) => {
                    const index = series.findIndex(obj => obj.name.toLowerCase() == setting.title.toLowerCase());

                    if (setting.enabled == 0 && setting.title == 'fat') {
                        series.splice(index, 1);
                    } else if (setting.enabled == 0 && setting.title == 'protein') {
                        series.splice(index, 1);
                    } else if (setting.enabled == 0 && setting.title == 'carbs') {
                        series.splice(index, 1);
                    }
                });

                return series;
            },

            parseSleepSeries () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;

                const chart = {
                    tooltip: {
                        position: 'top',
                        formatter: function(params) {
                            return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2]}`;
                        },
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                    },
                    animation: false,
                    grid: {
                        top: '2%',
                        left: '2%',
                        right: '2%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: vm.categoryProgress.chart_x_axis,
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: 1,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        inRange: {
                            color: vm.heatmapChartColors,
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                };

                const duration = {
                    name: 'Duration',
                    type: 'heatmap',
                    data: [],
                    label: {
                        show: true,
                        color: '#5a5a5a',
                        formatter: function(value) {
                            return `${value.data[2]}`;
                        }
                    },
                    itemStyle: {
                        borderWidth: 3,
                        borderColor: '#fff'
                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                }

                Object.values(scores).forEach((score, s) => {
                    if (isNaN(score)) {
                        duration.data.push([s, 0, score.duration]);
                    } else {
                        duration.data.push([s, 0, 0]);
                    }
                });

                chart.yAxis.data.push('Duration');
                chart.series.push(duration);

                const quality = {
                    name: 'Sleep Quality',
                    type: 'heatmap',
                    data: [],
                    label: {
                        show: true,
                        color: '#5a5a5a',
                        formatter: function(value) {
                            return `${value.data[2]}`;
                        }
                    },
                    itemStyle: {
                        borderWidth: 3,
                        borderColor: '#fff'
                    },
                    emphasis: {
                        itemStyle: {
                            opacity: 1,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                }

                Object.values(scores).forEach((score, s) => {
                    if (isNaN(score)) {
                        quality.data.push([s, 1, score.sleep_quality]);
                    } else {
                        quality.data.push([s, 1, 0]);
                    }
                });

                if (vm.categoryProgress.settings.has_sleep_quality) {
                    chart.yAxis.data.push('Sleep Quality');
                    chart.series.push(quality);
                }

                settings.forEach((setting, i) => {
                    let title = setting.title.replaceAll('_', ' ').replaceAll('rem', 'REM');
                        title = Helper.capitalizeFirstLetter(title);

                    if (setting.enabled) {
                        chart.yAxis.data.push(title);

                        const series = {
                            name: title,
                            type: 'heatmap',
                            data: [],
                            label: {
                                show: true,
                                color: '#5a5a5a',
                                formatter: function(value) {
                                    return `${value.data[2]}`;
                                }
                            },
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            emphasis: {
                                itemStyle: {
                                    opacity: 1,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        };

                        const index = chart.series.length;

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                series.data.push([s, index, score[setting.title]]);
                            } else {
                                series.data.push([s, index, 0]);
                            }
                        });

                        chart.series.push(series);
                    }
                });

                return chart;
            },

            parseMinutesSeconds (time) {
                const minutes = Math.floor(time / 60);
                const seconds = time - minutes * 60;

                return Helper.twoDigitNumber(minutes) + ':' + Helper.twoDigitNumber(seconds);
            },

            parseVitaminsMiniralSupplimentChart () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings;
                let scoreIndex = 0;

                const chart = {
                    tooltip: {
                        position: 'top',
                        formatter: function(params) {
                            return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'}`;
                        },
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                    },
                    animation: false,
                    grid: {
                        top: '2%',
                        left: '2%',
                        right: '2%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: vm.categoryProgress.chart_x_axis,
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: 1,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        inRange: {
                            color: vm.heatmapChartColors
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                };

                settings.forEach((setting, i) => {
                    if (setting.enabled) {
                        chart.yAxis.data.push(setting.title);

                        const series = {
                            name: setting.title,
                            type: 'heatmap',
                            data: [],
                            label: {
                                show: true,
                                color: '#5a5a5a',
                                formatter: function(value) {
                                    return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                }
                            },
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            emphasis: {
                                itemStyle: {
                                    opacity: 1,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        };

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                series.data.push([s, scoreIndex, score.includes(setting.title) ? 1 : 0]);
                            } else {
                                series.data.push([s, scoreIndex, 0]);
                            }
                        });

                        scoreIndex++;

                        chart.series.push(series);
                    }
                });

                return chart;
            },

            parseWorkoutChart () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;
                let scoreIndex = 0;

                const chart = {
                    tooltip: {
                        position: 'top',
                        formatter: function(params) {
                            return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'} <br />Duration: ${params.data[3]} min`;
                        },
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                    },
                    animation: false,
                    grid: {
                        top: '2%',
                        left: '2%',
                        right: '2%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: vm.categoryProgress.chart_x_axis,
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: 1,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        inRange: {
                            color: vm.heatmapChartColors
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                };

                settings.forEach((setting, i) => {
                    if (setting.enabled) {
                        chart.yAxis.data.push(setting.title);

                        const series = {
                            name: setting.title,
                            type: 'heatmap',
                            data: [],
                            label: {
                                show: true,
                                color: '#5a5a5a',
                                formatter: function(value) {
                                    return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                                }
                            },
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            emphasis: {
                                itemStyle: {
                                    opacity: 1,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        };

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                series.data.push([s, scoreIndex, score.workouts.includes(setting.title) ? 1 : 0, parseInt(score.duration[setting.title])]);
                            } else {
                                series.data.push([s, scoreIndex, 0, 0]);
                            }
                        });

                        scoreIndex++;

                        chart.series.push(series);
                    }
                });

                return chart;
            },

            parsePainChart (type = 'upper') {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const dataKey   = type == 'upper' ? 'upper_body' : 'lower_body';
                const settings  = vm.categoryProgress.settings[dataKey];
                let scoreIndex = 0;

                const chart = {
                    tooltip: {
                        position: 'top',
                        formatter: function(params) {
                            return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'} <br />Pain Level: ${params.data[3]} <br />Positions: ${params.data[5]}`;
                        },
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                    },
                    animation: false,
                    grid: {
                        top: '2%',
                        left: '2%',
                        right: '2%',
                        bottom: 80,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: vm.categoryProgress.chart_x_axis,
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: 1,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        inRange: {
                            color: vm.heatmapChartColors
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                };

                settings.forEach((setting, i) => {
                    if (setting.enabled) {
                        chart.yAxis.data.push(Helper.capitalizeFirstLetter(setting.title));

                        const series = {
                            name: Helper.capitalizeFirstLetter(setting.title),
                            type: 'heatmap',
                            data: [],
                            label: {
                                show: true,
                                color: '#5a5a5a',
                                formatter: function(value) {
                                    return `${value.data[2] == 1 ? value.data[4] : 0}`;
                                }
                            },
                            itemStyle: {
                                borderWidth: 3,
                                borderColor: '#fff'
                            },
                            emphasis: {
                                itemStyle: {
                                    opacity: 1,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                        };

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                const positions = score.positions[setting.title] ? score.positions[setting.title].join(', ') : 'NA';

                                series.data.push([s, scoreIndex, score[type].includes(setting.title) ? 1 : 0, `${parseInt(score.pain_level[setting.title])} / 10`, parseInt(score.pain_level[setting.title]), positions, score[type].includes(setting.title) ? 1 : 0]);
                            } else {
                                series.data.push([s, scoreIndex, 0, 0, '0/10', 0, 0]);
                            }
                        });

                        scoreIndex++;

                        chart.series.push(series);
                    }
                });

                return chart;
            },

            parseHeartRateZonesSeries () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;
                const goals     = vm.categoryProgress.settings.goals;
                const hasGoal   = vm.categoryProgress.has_goal && vm.tracking.has_goal;
                const series    = [];

                settings.forEach((setting, i) => {
                    if (setting.enabled) {
                        const goal = vm.goalForm.goals.filter((goal) => goal.title == setting.title)[0];

                        const data = {
                            name: setting.title,
                            data: [],
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'insideBottom',
                                distance: 20,
                                offset: [0, 6],
                                opacity: 1,
                                backgroundColor: 'transparent',
                                formatter: function(params) {
                                    return `${params.value}`;
                                }
                            },
                            itemStyle: {
                                color: vm.chartColor,
                                opacity: i == 1 ? 0.75 : (i == 2 ? 1 : 0.50),
                            },
                            markPoint: {
                                symbol: 'circle',
                                symbolSize: 20,
                                label: {
                                    fontSize: 10,
                                    color: '#fff'
                                },
                                data: [],
                            },
                            markLine: {
                                data: [
                                    { name: 'Max', yAxis: hasGoal && goal ? goal.high_limit : 0, label: { position: 'insideEndTop'} },
                                    { name: 'Min', yAxis: hasGoal && goal ? goal.low_limit : 0, label: { position: 'insideEndBottom'} },
                                ],
                                label: {
                                    formatter (params) {
                                        if (params.name == 'Less') {
                                            return `{down_image|} \n {value|${params.value}}`
                                        } else if (params.name == 'Greater') {
                                            return `{up_image|} \n {value|${params.value}}`
                                        }
                                    },
                                    color: vm.chartColor,
                                    fontFamily: "'Inter', sans-serif;",
                                    rich: {
                                        value: {
                                            align: 'center',
                                        },
                                        up_image: {
                                            height: 20,
                                            align: 'right',
                                            backgroundColor: {
                                                image: vm.arrowUpImage,
                                            }
                                        },
                                        down_image: {
                                            height: 20,
                                            align: 'right',
                                            backgroundColor: {
                                                image: vm.arrowDownImage,
                                            }
                                        }
                                    },
                                },
                                lineStyle:{
                                    opacity: 1,
                                    width: 0,
                                },
                                symbol: 'none',
                            },
                            markArea: {
                                data: [
                                    [
                                        { yAxis: hasGoal && goal ? goal.high_limit : 0 },
                                        { yAxis: hasGoal && goal ? goal.low_limit : 0 }
                                    ],
                                ],
                                itemStyle: {
                                    color: vm.markAreaColor,
                                    opacity: 1,
                                },
                            }
                        };

                        if (hasGoal) {
                            if (goal.type == 'Range') {
                                data.markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                data.markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                };
                            } else {
                                data.markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                data.markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        }

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                data.data.push(score[setting.title] ? score[setting.title] : 0);
                            } else {
                                data.data.push(0);
                            }
                        });

                        series.push(data);
                    }
                });

                return series;
            },

            parseBloodPressureSeries () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const settings  = vm.categoryProgress.settings.measurements;
                const goals     = vm.categoryProgress.settings.goals;
                const hasGoal   = vm.categoryProgress.has_goal && vm.tracking.has_goal;
                const series    = [];

                settings.forEach((setting, i) => {
                    if (setting.enabled) {
                        const goal = vm.goalForm.goals.filter((goal) => goal.title == setting.title)[0];

                        const data = {
                            name: Helper.capitalizeFirstLetter(setting.title),
                            data: [],
                            type: 'bar',
                            label: {
                                show: true,
                                position: 'insideBottom',
                                distance: 20,
                                offset: [0, 6],
                                opacity: 1,
                                backgroundColor: 'transparent',
                                formatter: function(params) {
                                    return `${params.value}`;
                                }
                            },
                            itemStyle: {
                                color: vm.chartColor,
                                opacity: i == 1 ? 0.75 : (i == 2 ? 0.50 : 1),
                            },
                            markPoint: {
                                symbol: 'circle',
                                symbolSize: 20,
                                label: {
                                    fontSize: 10,
                                    color: '#fff'
                                },
                                data: [],
                            },
                            markLine: {
                                data: [
                                    { name: 'Max', yAxis: hasGoal && goal ? goal.high_limit : 0, label: { position: 'insideEndTop'} },
                                    { name: 'Min', yAxis: hasGoal && goal ? goal.low_limit : 0, label: { position: 'insideEndBottom'} },
                                ],
                                label: {
                                    formatter (params) {
                                        if (params.name == 'Less') {
                                            return `{down_image|} \n {value|${params.value}}`
                                        } else if (params.name == 'Greater') {
                                            return `{up_image|} \n {value|${params.value}}`
                                        }
                                    },
                                    color: vm.chartColor,
                                    fontFamily: "'Inter', sans-serif;",
                                    rich: {
                                        value: {
                                            align: 'center',
                                        },
                                        up_image: {
                                            height: 20,
                                            align: 'right',
                                            backgroundColor: {
                                                image: vm.arrowUpImage,
                                            }
                                        },
                                        down_image: {
                                            height: 20,
                                            align: 'right',
                                            backgroundColor: {
                                                image: vm.arrowDownImage,
                                            }
                                        }
                                    },
                                },
                                lineStyle:{
                                    opacity: 1,
                                    width: 0,
                                },
                                symbol: 'none',
                            },
                            markArea: {
                                data: [
                                    [
                                        { yAxis: hasGoal && goal ? goal.high_limit : 0 },
                                        { yAxis: hasGoal && goal ? goal.low_limit : 0 }
                                    ],
                                ],
                                itemStyle: {
                                    color: vm.markAreaColor,
                                    opacity: 1,
                                },
                            }
                        };

                        if (hasGoal) {
                            if (goal.type == 'Range') {
                                data.markLine = {
                                    data: [
                                        { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                };

                                data.markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.high_limit },
                                            { yAxis: goal.low_limit }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                };
                            } else {
                                data.markLine = {
                                    data: [
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                        { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 1,
                                    },
                                    symbol: 'none',
                                };

                                data.markArea = {
                                    data: [
                                        [
                                            { yAxis: goal.target },
                                            { yAxis: goal.target },
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 0,
                                    },
                                };
                            }
                        }

                        Object.values(scores).forEach((score, s) => {
                            if (isNaN(score)) {
                                data.data.push(score[setting.title] ? score[setting.title] : 0);
                            } else {
                                data.data.push(0);
                            }
                        });

                        series.push(data);
                    }
                });

                return series;
            },

            parseStoolChart () {
                const vm        = this;
                const scores    = vm.categoryProgress.scores;
                const options   = vm.categoryProgress.options;

                const chart = {
                    tooltip: {
                        position: 'top',
                        formatter: function(params) {
                            return `${params.name + '<br />'} ${params.seriesName + ' : '} ${params.data[2] == 1 ? 'Yes' : 'No'}`;
                        },
                        textStyle: {
                            fontSize: 11,
                            color: '#5a5a5a',
                        },
                    },
                    animation: false,
                    grid: {
                        top: '3%',
                        left: -100,
                        right: '2%',
                        bottom: 60,
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        data: vm.categoryProgress.chart_x_axis,
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        splitArea: {
                            show: true
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            formatter: function (value) {
                                return '{' + value + '| }\n{value|' + (value.split('__')[0]).replaceAll('_', ' ') + '}';
                            },
                            margin: 120,
                            align: 'left',
                            rich: {
                                value: {
                                    lineHeight: 30,
                                    align: 'left'
                                },
                                'Severe_Constipation__1': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-1.png'
                                    }
                                },
                                'Mild_Constipation__2': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-2.png'
                                    }
                                },
                                'Normal__3': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-3.png'
                                    }
                                },
                                'Normal__4': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-4.png'
                                    }
                                },
                                'Lacking_Fiber__5': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-5.png'
                                    }
                                },
                                'Mild_Diarrhea__6': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-6.png'
                                    }
                                },
                                'Severe_Diarrhea__7': {
                                    height: 30,
                                    align: 'left',
                                    backgroundColor: {
                                        image: '/images/icons/bristol-type-7.png'
                                    }
                                },
                            },
                        },
                    },
                    visualMap: {
                        show: false,
                        min: 0,
                        max: 1,
                        calculable: true,
                        orient: 'horizontal',
                        left: 'center',
                        bottom: '15%',
                        inRange: {
                            color: vm.heatmapChartColors
                        }
                    },
                    series: [],
                    dataZoom: [{
                        start: 0,
                        end: 100,
                        moveHandleSize: 10,
                        moveHandleStyle: {
                            color: "#999",
                        },
                        height: 0,
                        showDataShadow: false,
                        showDetail: false,
                        handleStyle: {
                            opacity: 0
                        },
                    }],
                    media: [
                        {
                            query: {
                                maxWidth: 991
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 767
                            },
                            option: {
                                dataZoom: [{
                                    start: 0,
                                    end: 100,
                                }],
                            }
                        },
                        {
                            query: {
                                maxWidth: 499
                            },
                            option: {
                                dataZoom: [{
                                    start: 60,
                                    end: 100,
                                }],
                            }
                        },
                    ],
                };

                options.forEach((option, i) => {
                    chart.yAxis.data.push((option.name.replaceAll(' ', '_') + '__' + option.id));

                    const series = {
                        name: option.name,
                        type: 'heatmap',
                        data: [],
                        label: {
                            show: true,
                            color: '#5a5a5a',
                            formatter: function(value) {
                                return `${value.data[2] == 1 ? 'Yes' : 'No'}`;
                            }
                        },
                        itemStyle: {
                            borderWidth: 3,
                            borderColor: '#fff'
                        },
                        emphasis: {
                            itemStyle: {
                                opacity: 1,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                    };

                    Object.values(scores).forEach((score, s) => {
                        if (isNaN(score)) {
                            series.data.push([s, i, score.id == option.id ? 1 : 0]);
                        } else {
                            series.data.push([s, i, 0]);
                        }
                    });

                    chart.series.push(series);
                });

                return chart;
            },

            handleCircumferencesChart (measurement = null) {
                const vm        = this;
                const hasGoal   = vm.categoryProgress.has_goal && vm.tracking.has_goal;
                const goals     = vm.categoryProgress.settings.goals;

                let circumferences  = vm.categoryProgress.settings.measurements;
                    circumferences  = circumferences.filter(item => item.enabled);

                if (measurement) {
                    let hasMeasurement  = vm.circumferencesChartData.findIndex((series) => series.name === Helper.capitalizeFirstLetter(measurement.title.replaceAll('_', ' ')));
                    const circumference = circumferences.filter(item => item.title == measurement.title)[0];

                    if (hasMeasurement == -1) {
                        if (vm.circumferencesChartData.length < 3) {
                            if (circumference) {
                                let scores = [];
                                const goal = vm.goalForm.goals.filter(goal => goal.title == circumference.title)[0];

                                Object.values(vm.categoryProgress.scores).forEach((score) => {
                                    if (isNaN(score)) {
                                        scores.push(score[circumference.title]);
                                    } else {
                                        scores.push(0);
                                    }
                                });

                                const series = {
                                    name: Helper.capitalizeFirstLetter(circumference.title.replaceAll('_', ' ')),
                                    type: 'bar',
                                    label: {
                                        show: true,
                                        position: 'insideBottom',
                                        distance: 10,
                                        opacity: 1,
                                        backgroundColor: 'transparent',
                                        formatter: function(params) {
                                            return `${params.value}`;
                                        }
                                    },
                                    data: scores,
                                    itemStyle: {
                                        color: vm.chartColor,
                                        opacity: 0.50,
                                    },
                                    markPoint: {
                                        symbol: 'circle',
                                        symbolSize: 20,
                                        symbolOffset: [0, -15],
                                        label: {
                                            fontSize: 10,
                                            color: '#fff'
                                        },
                                        data: [],
                                    },
                                    markLine: {
                                        data: [
                                            { name: 'Max', yAxis: hasGoal && goal ? goal.high_limit : 0, label: { position: 'insideEndTop'} },
                                            { name: 'Min', yAxis: hasGoal && goal ? goal.low_limit : 0, label: { position: 'insideEndBottom'} },
                                        ],
                                        label: {
                                            formatter (params) {
                                                if (params.name == 'Less') {
                                                    return `{down_image|} \n {value|${params.value}}`
                                                } else if (params.name == 'Greater') {
                                                    return `{up_image|} \n {value|${params.value}}`
                                                }
                                            },
                                            color: vm.chartColor,
                                            fontFamily: "'Inter', sans-serif;",
                                            rich: {
                                                value: {
                                                    align: 'center',
                                                },
                                                up_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowUpImage,
                                                    }
                                                },
                                                down_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowDownImage,
                                                    }
                                                }
                                            },
                                        },
                                        lineStyle:{
                                            opacity: 1,
                                            width: 0,
                                        },
                                        symbol: 'none',
                                    },
                                    markArea: {
                                        data: [
                                            [
                                                { yAxis: hasGoal && goal ? goal.high_limit : 0 },
                                                { yAxis: hasGoal && goal ? goal.low_limit : 0 }
                                            ],
                                        ],
                                        itemStyle: {
                                            color: vm.markAreaColor,
                                            opacity: 1,
                                        },
                                    }
                                };

                                if (hasGoal) {
                                    if (goal.type == 'Range') {
                                        series.markLine = {
                                            data: [
                                                { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                                { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                            ],
                                            label: {
                                                formatter (params) {
                                                    if (params.name == 'Less') {
                                                        return `{down_image|} \n {value|${params.value}}`
                                                    } else if (params.name == 'Greater') {
                                                        return `{up_image|} \n {value|${params.value}}`
                                                    }
                                                },
                                                color: vm.chartColor,
                                                fontFamily: "'Inter', sans-serif;",
                                                rich: {
                                                    value: {
                                                        align: 'center',
                                                    },
                                                    up_image: {
                                                        height: 20,
                                                        align: 'right',
                                                        backgroundColor: {
                                                            image: vm.arrowUpImage,
                                                        }
                                                    },
                                                    down_image: {
                                                        height: 20,
                                                        align: 'right',
                                                        backgroundColor: {
                                                            image: vm.arrowDownImage,
                                                        }
                                                    }
                                                },
                                            },
                                            lineStyle:{
                                                opacity: 1,
                                                width: 0,
                                            },
                                            symbol: 'none',
                                        };

                                        series.markArea = {
                                            data: [
                                                [
                                                    { yAxis: goal.high_limit },
                                                    { yAxis: goal.low_limit }
                                                ],
                                            ],
                                            itemStyle: {
                                                color: vm.markAreaColor,
                                                opacity: 1,
                                            },
                                        };
                                    } else {
                                        series.markLine = {
                                            data: [
                                                { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                                { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                            ],
                                            label: {
                                                formatter (params) {
                                                    if (params.name == 'Less') {
                                                        return `{down_image|} \n {value|${params.value}}`
                                                    } else if (params.name == 'Greater') {
                                                        return `{up_image|} \n {value|${params.value}}`
                                                    }
                                                },
                                                color: vm.chartColor,
                                                fontFamily: "'Inter', sans-serif;",
                                                rich: {
                                                    value: {
                                                        align: 'center',
                                                    },
                                                    up_image: {
                                                        height: 20,
                                                        align: 'right',
                                                        backgroundColor: {
                                                            image: vm.arrowUpImage,
                                                        }
                                                    },
                                                    down_image: {
                                                        height: 20,
                                                        align: 'right',
                                                        backgroundColor: {
                                                            image: vm.arrowDownImage,
                                                        }
                                                    }
                                                },
                                            },
                                            lineStyle:{
                                                opacity: 1,
                                                width: 1,
                                            },
                                            symbol: 'none',
                                        };

                                        series.markArea = {
                                            data: [
                                                [
                                                    { yAxis: goal.target },
                                                    { yAxis: goal.target },
                                                ],
                                            ],
                                            itemStyle: {
                                                color: vm.markAreaColor,
                                                opacity: 0,
                                            },
                                        };
                                    }
                                }

                                vm.chart.legend.data.push(Helper.capitalizeFirstLetter(circumference.title.replaceAll('_', ' ')));
                                vm.circumferencesChartData.push(series);
                            }
                        }
                    } else {
                        vm.circumferencesChartData.splice(hasMeasurement, 1);
                    }

                    vm.circumferencesChartData.forEach((series, s) => {
                        vm.circumferencesChartData[s].itemStyle.opacity = s == 1 ? 0.75 : (s == 2 ? 0.50 : 1);
                    });

                    vm.chart.series     = vm.circumferencesChartData;

                    if (vm.categoryProgress.has_goal && vm.tracking.has_goal) {
                        vm.markChartPoints();
                    } else {
                        vm.chart.series.forEach((s, i) => {
                            delete vm.chart.series[i].markPoint;
                            delete vm.chart.series[i].markLine;
                            delete vm.chart.series[i].markArea;
                        });
                    }

                    if (vm.$refs.chart) {
                        vm.$refs.chart.refreshChart();
                    }
                } else {
                    circumferences.forEach((circumference, c) => {
                        if (c <= 2) {
                            let scores = [];

                            const goal = vm.goalForm.goals.filter(goal => goal.title == circumference.title)[0];

                            Object.values(vm.categoryProgress.scores).forEach((score) => {
                                if (isNaN(score)) {
                                    scores.push(score[circumference.title]);
                                } else {
                                    scores.push(0);
                                }
                            });

                            const series = {
                                name: Helper.capitalizeFirstLetter(circumference.title.replaceAll('_', ' ')),
                                type: 'bar',
                                label: {
                                    show: true,
                                    position: 'insideBottom',
                                    distance: 10,
                                    opacity: 1,
                                    backgroundColor: 'transparent',
                                    formatter: function(params) {
                                        return `${params.value}`;
                                    }
                                },
                                data: scores,
                                itemStyle: {
                                    color: vm.chartColor,
                                    opacity: c == 1 ? 0.75 : (c == 2 ? 0.50 : 1),
                                },
                                markPoint: {
                                    symbol: 'circle',
                                    symbolSize: 20,
                                    symbolOffset: [0, -15],
                                    label: {
                                        fontSize: 10,
                                        color: '#fff'
                                    },
                                    data: [],
                                },
                                markLine: {
                                    data: [
                                        { name: 'Max', yAxis: hasGoal && goal ? goal.high_limit : 0, label: { position: 'insideEndTop'} },
                                        { name: 'Min', yAxis: hasGoal && goal ? goal.low_limit : 0, label: { position: 'insideEndBottom'} },
                                    ],
                                    label: {
                                        formatter (params) {
                                            if (params.name == 'Less') {
                                                return `{down_image|} \n {value|${params.value}}`
                                            } else if (params.name == 'Greater') {
                                                return `{up_image|} \n {value|${params.value}}`
                                            }
                                        },
                                        color: vm.chartColor,
                                        fontFamily: "'Inter', sans-serif;",
                                        rich: {
                                            value: {
                                                align: 'center',
                                            },
                                            up_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowUpImage,
                                                }
                                            },
                                            down_image: {
                                                height: 20,
                                                align: 'right',
                                                backgroundColor: {
                                                    image: vm.arrowDownImage,
                                                }
                                            }
                                        },
                                    },
                                    lineStyle:{
                                        opacity: 1,
                                        width: 0,
                                    },
                                    symbol: 'none',
                                },
                                markArea: {
                                    data: [
                                        [
                                            { yAxis: hasGoal && goal ? goal.high_limit : 0 },
                                            { yAxis: hasGoal && goal ? goal.low_limit : 0 }
                                        ],
                                    ],
                                    itemStyle: {
                                        color: vm.markAreaColor,
                                        opacity: 1,
                                    },
                                }
                            };

                            if (hasGoal) {
                                if (goal.type == 'Range') {
                                    series.markLine = {
                                        data: [
                                            { name: 'Max', yAxis: goal.high_limit, label: { position: 'insideEndTop'} },
                                            { name: 'Min', yAxis: goal.low_limit, label: { position: 'insideEndBottom'} },
                                        ],
                                        label: {
                                            formatter (params) {
                                                if (params.name == 'Less') {
                                                    return `{down_image|} \n {value|${params.value}}`
                                                } else if (params.name == 'Greater') {
                                                    return `{up_image|} \n {value|${params.value}}`
                                                }
                                            },
                                            color: vm.chartColor,
                                            fontFamily: "'Inter', sans-serif;",
                                            rich: {
                                                value: {
                                                    align: 'center',
                                                },
                                                up_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowUpImage,
                                                    }
                                                },
                                                down_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowDownImage,
                                                    }
                                                }
                                            },
                                        },
                                        lineStyle:{
                                            opacity: 1,
                                            width: 0,
                                        },
                                        symbol: 'none',
                                    };

                                    series.markArea = {
                                        data: [
                                            [
                                                { yAxis: goal.high_limit },
                                                { yAxis: goal.low_limit }
                                            ],
                                        ],
                                        itemStyle: {
                                            color: vm.markAreaColor,
                                            opacity: 1,
                                        },
                                    };
                                } else {
                                    series.markLine = {
                                        data: [
                                            { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Less' ? 'insideEndBottom' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndTop')} },
                                            { name: goal.target_type, yAxis: goal.target, label: { position: goal.target_type == 'Greater' ? 'insideEndTop' : (goal.target_type == 'Equal' ? 'insideEndTop' : 'insideEndBottom')} },
                                        ],
                                        label: {
                                            formatter (params) {
                                                if (params.name == 'Less') {
                                                    return `{down_image|} \n {value|${params.value}}`
                                                } else if (params.name == 'Greater') {
                                                    return `{up_image|} \n {value|${params.value}}`
                                                }
                                            },
                                            color: vm.chartColor,
                                            fontFamily: "'Inter', sans-serif;",
                                            rich: {
                                                value: {
                                                    align: 'center',
                                                },
                                                up_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowUpImage,
                                                    }
                                                },
                                                down_image: {
                                                    height: 20,
                                                    align: 'right',
                                                    backgroundColor: {
                                                        image: vm.arrowDownImage,
                                                    }
                                                }
                                            },
                                        },
                                        lineStyle:{
                                            opacity: 1,
                                            width: 1,
                                        },
                                        symbol: 'none',
                                    };

                                    series.markArea = {
                                        data: [
                                            [
                                                { yAxis: goal.target },
                                                { yAxis: goal.target },
                                            ],
                                        ],
                                        itemStyle: {
                                            color: vm.markAreaColor,
                                            opacity: 0,
                                        },
                                    };
                                }
                            }

                            vm.circumferencesChartData.push(series);
                        }
                    });
                }
            },

            saveGoal () {
                const vm = this;

                const params = {
                    progress_id: vm.categoryProgress.progress_tracking_id,
                    progress_category_id: vm.categoryProgress.progress_tracking_category_id,
                    progress_progress_id: vm.categoryProgress.id,
                    goals: vm.goalForm,
                    mode: vm.viewType,
                };

                vm.goalUpdateLoader = true;

                // axios.post(route('progress-tracking.update-goal', { accessToken: vm.accessToken, progress: vm.tracking.id }), Helper.cleanObject(params)).then((resp) => {
                //     Toastr.successNotification(this, resp.data.message);

                //     vm.goalUpdateLoader = false;
                //     vm.updateGoalModal = false;

                //     if (vm.categoryProgress.is_pain) {
                //         vm.resetPainChart();
                //     } else {
                //         vm.resetChart();
                //     }
                // }).catch((err) => {
                //     Toastr.errorNotification(this, 'Something went wrong, please try after some time!');

                //     vm.goalUpdateLoader = false;
                // });
            },

            handleChartClick (params) {
                const vm = this;

                if (vm.categoryProgress.is_pain) {
                    vm.painDetailsDrawer = true;
                } else if (vm.categoryProgress.is_pull_up || vm.categoryProgress.is_push_up) {
                    vm.pullUpPushUpDetailsDrawer = true;
                } else if (vm.categoryProgress.is_macros) {
                    vm.macrosDetailsDrawer = true;
                } else if (vm.categoryProgress.is_circumference) {
                    vm.circumferencesDetailsDrawer = true;
                }

                vm.selectedChartSeries = {
                    seriesName: params.seriesName,
                    componentIndex: params.componentIndex,
                    value: params.value,
                    date: params.name,
                    color: params.color,
                    dataIndex: params.dataIndex,
                };
            },

            toggleTargetDropdown (event) {
                const child = event.target.querySelector('.dropdown_wpr');

                if (child) {
                    if (child.classList.contains('active')) {
                        child.classList.remove('active');
                    } else {
                        child.classList.add('active');
                    }
                }
            },

            goalStatusLabel (title, dataIndex) {
                const vm    = this;
                const type  = vm.goalForm.type;
                const goal  = vm.goalForm.goals ? vm.goalForm.goals.filter(g => g.title.toLowerCase() == title.toLowerCase())[0] : vm.goalForm;
                const score = parseInt(Object.values(vm.categoryProgress.scores)[dataIndex][title]);
                let status  = 'In Range';

                if (type == 'Range') {
                    if (score > goal.high_limit) {
                        status = 'High';
                    } else if (score < goal.low_limit) {
                        status = 'Low';
                    }
                } else if (type == 'Target') {
                    if (goal.target_type == 'Greater' && score < parseInt(goal.target)) {
                        status = 'Low';
                    } else if (goal.target_type == 'Less' && score > parseInt(goal.target)) {
                        status = 'High';
                    } else if (goal.target_type == 'Equal') {
                        if (score > parseInt(goal.target)) {
                            status = 'High';
                        } else if (score < parseInt(goal.target)) {
                            status = 'Low';
                        }
                    }
                }

                return status;
            },

            goalStatusShortLabel (title, dataIndex) {
                const vm    = this;
                const type  = vm.goalForm.type;
                const goal  = vm.goalForm.goals ? vm.goalForm.goals.filter(g => g.title.toLowerCase() == title.toLowerCase())[0] : vm.goalForm;
                const score = parseInt(Object.values(vm.categoryProgress.scores)[dataIndex][title]);
                let status  = '★';

                if (type == 'Range') {
                    if (score > goal.high_limit) {
                        status = 'H';
                    } else if (score < goal.low_limit) {
                        status = 'L';
                    }
                } else if (type == 'Target') {
                    if (goal.target_type == 'Greater' && score < parseInt(goal.target)) {
                        status = 'L';
                    } else if (goal.target_type == 'Less' && score > parseInt(goal.target)) {
                        status = 'H';
                    } else if (goal.target_type == 'Equal') {
                        if (score > parseInt(goal.target)) {
                            status = 'H';
                        } else if (score < parseInt(goal.target)) {
                            status = 'L';
                        }
                    }
                }

                return status;
            },

            goalDataLabel (title) {
                const vm    = this;
                const type  = vm.goalForm.type;
                const goal  = vm.goalForm.goals ? vm.goalForm.goals.filter(g => g.title.toLowerCase() == title.toLowerCase())[0] : vm.goalForm;
                let html    = '';

                if (vm.categoryProgress.has_goal && vm.tracking.has_goal) {
                    if (type == 'Range') {
                        html = `<span>High Limit: ${goal.high_limit}</span><span>Low Limit: ${goal.low_limit}</span>`;
                    } else if (type == 'Target') {
                        if (goal.target_type == 'Greater') {
                            html = `<span>Greater Than: ${goal.target}</span>`;
                        } else if (goal.target_type == 'Less') {
                            html = `<span>Less Than: ${goal.target}</span>`;
                        } else if (goal.target_type == 'Equal') {
                            html = `<span>Equal To: ${goal.target}</span>`;
                        }
                    }
                }

                if (vm.categoryProgress.unit_label) {
                    html += `<span>Unit: ${ vm.categoryProgress.unit_label }</span>`;
                }

                return html;
            },

            hexToRgb (hex) {
                let c;

                if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                    c = hex.substring(1).split('');

                    if (c.length== 3) {
                        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
                    }

                    c = '0x' + c.join('');

                    return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
                }

                return null;
            }
        }
    }
</script>

<style scoped>
.chart_title {
    font-size: 18px;
    line-height: 25px;
    color: #121525;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
}

.chart_title img {
    max-height: 30px;
    width: auto;
    padding-right: 10px;
}

.chart_title .tag {
    background: #d9d9d9;
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    margin-left: 10px;
}

.widgets_wpr {
    padding: 20px 30px;
    margin-bottom: 40px;
    position: relative;
}

.widgets_wpr .slider_cover {
    width: 100%;
    height: 20px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 49px;
}

.widgets_wpr:hover .slider_cover {
    display: none;
}

.widgets_wpr .header {
    align-items: center;
}

.item_unit {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    background: #f5f5f5;
    padding: 5px 10px;
    border-radius: 15px;
    color: #5a5a5a;
    margin: 0 10px 0 auto;
}

.item_unit span {
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
}

.chart_setup {
    font-size: 16px;
    position: relative;
    cursor: pointer;
}

.chart_setup .goal_option {
    position: absolute;
    width: 330px;
    right: 0;
    top: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 20px rgb(0, 0, 0, 0.15);
    margin-top: 10px;
    max-height: 320px;
    overflow-y: scroll;
    z-index: 1;
    display: none;
    flex-wrap: wrap;
    opacity: 0;
}

.chart_setup .goal_option::-webkit-scrollbar {
    width: 4px;
}

.chart_setup .goal_option::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #dbdbdb;
}

.chart_setup .goal_option .subgoal_item {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0 20px 0;
}

.chart_setup .goal_option .subgoal_item .title {
    flex-grow: 1;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: #121525;
    padding: 5px 15px;
    margin: 0 -15px;
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
}

.chart_setup .goal_option .goal_item {
    width: 50%;
    padding: 15px 15px 25px 15px;
}

.chart_setup .goal_option .goal_item:nth-of-type(1) {
    border-right: 1px solid #e9e9e9;
}

.chart_setup .goal_option .goal_item h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.chart_setup .goal_option .goal_item ul {
    flex-direction: column;
    margin-top: 15px;
}

.chart_setup .goal_option .goal_item ul li {
    width: 100%;
    height: auto;
    border: 0;
    margin: 0;
}

.chart_setup .goal_option .goal_item ul li label {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 400;
    padding-bottom: 3px;
    display: block;
}

.chart_setup .goal_option .goal_item ul li .field_area {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
}

.chart_setup .goal_option .goal_item ul li .field_area input {
    height: 28px;
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    background: transparent;
}

.chart_setup .goal_option .goal_item ul li .field_area .unit {
    background: #f5f5f5;
    border-left: 1px solid #e9e9e9;
    border-radius: 0 5px 5px 0;
    height: 28px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
}

.chart_setup .goal_option .action_wpr {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: #fff;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    margin: 0;
    padding: 12px 20px;
}

.chart_setup .goal_option .action_wpr .btn {
    min-width: auto;
    height: 28px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    padding: 6px 15px;
}

.chart_setup .goal_option.active {
    animation: dropMove 0.5s ease-in-out;
    display: flex;
    opacity: 1;
}

.chart_setup .target_drops {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #5a5a5a;
    padding: 6px 25px 6px 10px;
}

.chart_setup .target_drops>i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e9e9e9;
    font-size: 13px;
    color: #767676;
    pointer-events: none;
}

.chart_setup .target_drops .dropdown_wpr {
    z-index: 3;
}

.chart_setup .target_drops .dropdown_wpr ul {
    margin: 0;
    gap: 0;
}

.chart_setup .target_drops .dropdown_wpr ul li {
    padding: 8px 14px;
}

.widgets_wpr span.chart_title {
    left: -20px;
    min-width: 80px;
    display: flex;
    justify-content: center;
}

@keyframes dropMove {
    0% {
        margin-top: 1px;
        opacity: 0;
    }

    100% {
        margin-top: 10px;
        opacity: 1;
    }
}

.modal.track_modal {
    z-index: 13;
}

.track_modal .modal_body {
    background: #fff;
}

.range_limit {
    border-radius: 8px;
    margin-bottom: 30px;
}

.half_pie_wpr {
    height: 230px;
    overflow: hidden;
}

.range_limit .scope_details {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 30px;
}

.range_limit .scope_details .scope_item {
    border: 2px solid #fff;
    background: #ebf3ff;
    border-radius: 3px;
    flex-grow: 1;
    padding: 10px;
    display: flex;
    align-items: flex-end;
}

.range_limit .scope_details .scope_item h4 {
    font-size: 13px;
    line-height: 18px;
    color: #121525;
    font-weight: 500;
}

.range_limit .scope_details .scope_item .item_data {
    font-size: 15px;
    line-height: 18px;
    color: #2f7eed;
    font-weight: 500;
    padding-left: 10px;
}

.range_limit .scope_details .scope_item .item_data span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    padding-left: 3px;
}

/* .range_limit .scope_details .scope_item .item_data{
    background: #f5f5f5;
    border-radius: 3px;
    flex-grow: 1;
    padding: 10px 15px;
    display: flex;
    align-items: center;
} */
.range_limit h3 {
    font-size: 17px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
}

.range_limit h3 .range_details {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    font-weight: 500;
    margin: 0 -7px 3px -7px;
}

.range_limit h3 .range_details span {
    padding: 0 7px;
    border-right: 1px solid #ccc;
}

.range_limit h3 .range_details span:last-of-type {
    border: 0;
}

.range_limit ul {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    gap: 10px;
}

.range_limit ul li {
    background: #f5f5f5;
    border-radius: 5px;
    flex-grow: 1;
    padding: 12px 20px;
    display: flex;
    align-items: center;
}

.range_limit ul li h4 {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.range_limit ul li h4 label {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 400;
    padding-bottom: 7px;

}

.range_limit ul li .flag {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    line-height: 25px;
    font-weight: 500;
    background: #999;
    color: #fff;
    margin-left: auto;
}

.range_limit ul li .flag.winner {
    display: none;
}

.range_limit ul li span {
    font-size: 11px;
    line-height: 14px;
    color: #121525;
    font-weight: 500;
    padding-left: 3px;
}

.pain_limit {
    margin-bottom: 30px;
}

.pain_limit .pain_item {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 12px 20px;
    margin-bottom: 15px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
}

.pain_limit .pain_item label {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    padding-bottom: 7px;
    display: block;
}

.pain_item .positions ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px;
}

.pain_item .positions ul li {
    font-size: 11px;
    line-height: 14px;
    color: #fff;
    font-weight: 400;
    padding: 2px 10px;
    border-radius: 10px;
    background: #888;
    margin: 3px;
}

.pain_limit .pain_item h4 {
    font-size: 15px;
    line-height: 20px;
    color: #121525;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pain_limit .pain_item h4 label {
    padding: 3px 0 0 0;
    font-size: 11px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 1px;
}

.additional_notes {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 20px;
    color: #5a5a5a;
    font-weight: 400;
}

.section_title {
    font-size: 20px;
    line-height: 28px;
    color: #0D0D0D;
    font-weight: 400;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.show_hide {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #5a5a5a;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    padding: 3px 10px;
    border-radius: 15px;
    margin-left: auto;
}

.scroll_table {
    overflow-x: auto;
    border: 1px solid #e9e9e9;
    border-bottom: 0;
    border-radius: 5px;
    max-height: 400px;
}

.scroll_table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: #fbfbfb;
}

.scroll_table::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 5px;
}

.chart_data {
    min-width: 350px;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
}

.chart_data th,
.chart_data td {
    padding: 10px 0;
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    border-bottom: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
}

.chart_data th:last-child,
.chart_data td:last-child {
    border-right: 0;
}

.chart_data th {
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
}

.chart_data th:first-child {
    z-index: 2;
    left: 0;
}

.chart_data td {
    background: #fff;
    color: #5a5a5a;
    min-width: 100px;
}

.chart_data td.date {
    min-width: 100px;
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 0;
}

.chart_data th:first-child:after,
.chart_data td.date:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid #e9e9e9;
}

.chart_data td h5 {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #121525;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart_data td h5 span {
    font-size: 9px;
    line-height: 11px;
    padding-left: 2px;
}

.chart_data td h5 label.state {
    font-size: 10px;
    margin-left: 5px;
}

.chart_data td h5 label.state {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #999;
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    color: #fff;
    margin-left: 7px;
}

.chart_data td h5 label.state.success {
    display: none;
}

.scope_box {
    width: auto;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    position: relative;
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: #121525;
    padding: 8px 30px 8px 10px;
    margin-right: 15px;
    min-width: 120px;
    cursor: pointer;
}

.scope_box>i {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eeeeee;
    font-size: 13px;
    color: #999;
}

.scope_box .dropdown_wpr {
    overflow: hidden;
}

.scope_box .dropdown_wpr ul {
    padding: 5px 15px;
    justify-content: flex-start;
    gap: 0;
}

.scope_box .dropdown_wpr ul li {
    border: 0;
    padding: 10px 0;
    height: auto;
    min-width: 100px;
    width: auto;
}

.scope_box .dropdown_wpr ul li label.checkbox {
    justify-content: flex-start;
}

.scope_box .dropdown_wpr ul li label.checkbox span i {
    color: #7a7a7a;
}

.scope_box .dropdown_wpr ul li label.checkbox span.active i {
    transform: scale(1);
    opacity: 1;
}

.scope_box .dropdown_wpr ul li label.checkbox h5 {
    font-size: 11px;
    line-height: 15px;
    color: #121525;
    font-weight: 500;
    padding-left: 10px;
}

.widgets_wpr ul li label.checkbox span {
    width: 16px;
    flex: 0 0 16px;
    height: 16px;
    border: 1px solid #BABDC3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding: 0 !important;
    position: relative;
}

.tabs {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin-left: 15px;
}

.tabs li {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #121525;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
}

.tabs li.active {
    color: #2f7FED
}

@media(max-width: 499px) {
    .widgets_wpr {
        padding: 20px;
    }

    .item_unit {
        font-size: 11px;
        line-height: 15px;
    }

    .item_unit span {
        font-size: 13px;
        line-height: 15px;
    }

    .chart_setup .goal_option {
        right: -12px;
    }

    .widgets_wpr .slider_cover {
        display: none;
    }
}
</style>